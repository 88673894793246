import { mapActions, mapGetters } from "vuex";
import appHeader from "@shared/app-header";
import appSidebar from "@/components/app-sidebar";
import alert from "@/components/alert-box";
import pagination from "@/components/pagination";
import { checkPermission } from "@shared/utils/functions";

export default {
  name: 'dashboard',

  components: {
    appHeader,
    appSidebar,
    alert,
    pagination,
  },

  data () {
    return {
      isLoading: true,
    }
  },

  title: "Dashboard",

  computed: {
    ...mapGetters([
      "getTenantId",
      "getNotificationCount",
      "getThemeUseDefault",
      "getThemeColors",
      "getDefaultColors",
    ]),

    themeColors() {
      const colors = this.getThemeUseDefault === false ? this.getThemeColors : this.getDefaultColors;
      return Object.values(colors).reduce((result, color) => {
        if (color.value) result[color.var] = color.value;
        return result;
      }, {})
    },
  },

  async mounted () {
    this.isLoading = true;
    await this.fetchNewTenantId();
    await this.initApp();
    this.isLoading = false;
  },

  methods: {
    checkPermission,
    ...mapActions({
      fetchNotificationCount: "fetchNotificationCount",
      fetchUIConf: "fetchUIConf",
      fetchTenantId: "fetchTenantId",
      fetchUserLang: "LangStore/fetchUserLang",
  }),

    async fetchNewTenantId() {
      await this.fetchNotificationCount()
    },

    async initTenant() {
      try {
        await this.fetchTenantId();
      } catch(e) {
        this.$toast.error("Initialization error");
      } 

      if (this.getTenantId) {
          await this.fetchUIConf(this.getTenantId);
      }
    },

    async initLang() {
      if (!this.checkPermission("language.language_dropdown")) return;
      try {
        this.fetchUserLang();
      } catch (error) {
        console.error("Failed to load language preferences", error);
      }
    },

    async initApp() {
      console.log("initApp dashboard");
      await Promise.all([
        this.initTenant(),
        this.initLang(),
      ])
    }
  },
}
