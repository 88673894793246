import { delviumLogo } from "@/plugins/common"
// import { defaultLogo } from "@/plugins/common"

import { mapGetters } from "vuex";

import PaintIcon from '@/assets/icons/svg/paint.svg';
import Domain from '@/assets/icons/svg/domain.svg';
import report from '@/assets/icons/svg/clipboard-list.svg';
import WorkflowSvg from '../../assets/icons/workflow/workflowMainMenu.svg'
import WorkflowCase from '../../assets/icons/workflow/workflowCase.svg'
import WorkflowCheck from '../../assets/icons/workflow/workflowCheck.svg'
import PersonIcon from "@shared/assets/icons/entity/person.svg";
import CompanyIcon from "@shared/assets/icons/entity/company.svg";
import taxIcon from "@/assets/icons/svg/manage-tax-main.svg"
import Invoices from '@/assets/icons/svg/invoices.svg';
import Orders from '@/assets/icons/svg/orders.svg';
import Sales from '@/assets/icons/svg/sales.svg';
import creditPackIcon from '@/assets/icons/svg/credit-pack.svg';
import DashboardIcon from '@/assets/icons/svg/dashboard.svg';
import License from '@/assets/icons/svg/license.svg';
import {getSaasUrl} from "@shared/light-ray/services";
import { cloneDeep } from "lodash";
import DefaultEmail from "@/assets/icons/email-admin/icon-defaultemail.svg";
import EmailSequence from "@/assets/icons/email-admin/icon-templatesequence.svg";
import salesMain from "@/assets/icons/svg/sales-main.svg"
import salesOrders from "@/assets/icons/svg/sales-orders.svg"
import { checkPermission } from "@shared/utils/functions"

export default {
  name: "app-sidebar",
  components: {
    DefaultEmail,
    EmailSequence
  },
  props: [],
  data() {
    return {
      navigation: [],
      dashboard: {
        id: "Dashboard",
        link: "/",
        svg: DashboardIcon,
        pathAliase: '/case-dashboard',
      },
      //  Admin menu
      admin:{
        id: "Admin",
        link: "/admin",
        icon: "user-shield",
        children: [
        ],
      },
      field:{
        id: "Fields",
        link: "/field-admin",
        icon: "table",
      },
      check:{
        id: "Checks",
        link: "/check-admin",
        icon: "check",
      },
      package:{
        id: "Packages",
        link: "/packages",
        icon: "database",
      },
      riskAssessment:{
        id: "Risk Assessment",
        link: "/risk-assessment",
        icon: "user-shield",
      },
      clientList:{
        id: "Clients",
        link: "/clients-list",
        icon: "user-secret",
      },
      user:{
        id: "Users",
        link: "/users",
        icon: "user",
      },
      registeredDomain:{
        id: "Registered Domain",
        link: "/registered-domain",
        icon: "user",
      },
      group:{
        id: "Groups",
        link: "/groups",
        icon: "users-cog",
      },
      email:{
        id: "emails",
        link: "/emails",
        icon: "envelope-open",
        children: [
          {
            id: "Templates & Sequences",
            link: "/email-admin",
            customEmail: true,
            svg: EmailSequence,
          },
          {
            id: "Default Template & Sequences",
            link: "/default-template",
            customEmail: true,
            svg: DefaultEmail,
          },
        ],
      },
      form:{
        id: "Forms",
        link: "/form-admin",
        icon: "keyboard",
      },
      rolePermission:{
        id: "Roles & Permissions",
        link: "/roles-and-permissions",
        icon: "user",
      },
      creditPack:{
        id: "Credit Packs",
        link: "/credit-packs",
        svg: creditPackIcon,
      },
      license:{
        id: "License",
        link: "/license",
        svg: License,
      },
      tax: {
        id: "Manage Tax",
        link: "/manage-tax",
        svg: taxIcon,
      },
      // Purcahse 
      purchase: {
        id: "Purchase",
        link: "/purchase",
        svg: Sales,
        children: [
        ],
      },
      purchaseOrder:{
        id: "Orders",
        link: "/purchase-order",
        svg: Orders,
      },
      purchaseInvoice:
      {
        id: "Invoices",
        link: "/invoice",
        svg: Invoices,
      },
      sales:{
        id: "Sales",
        link: "/sales",
        svg: salesMain,
        children: [
        ]
      },
      salesOrders:{
        id: "Orders",
        link: "/sales-orders",
        svg: salesOrders
      },
      salesInvoice:{
        id: "Invoices",
        link: "/sales-invoice",
        svg: Invoices,
      },
      setting:{
        id: "Settings",
        link: "/settings",
        icon: "cog",
        children: [
        ],
      },
      logo:{
        id: "Logo & Theme",
        link: "/logo-and-theme",
        svg: PaintIcon,
      },
      domain: {
        id: "Custom Domain",
        link: "/custom-domain",
        svg: Domain,
      },
      workflowEngine: {
        id: "Workflow Engine",
        link: "/workflow",
        svg: WorkflowSvg,
        children: [
          {
            id: "Person",
            link: "/workflow-engine/person",
            svg: PersonIcon,
          },
          {
            id: "Company",
            link: "/workflow-engine/company",
            svg: CompanyIcon,
          },
          {
            id: "Case",
            link: "/workflow-engine/case",
            svg: WorkflowCase,
          },
          {
            id: "Checks",
            link: "/workflow-engine/check",
            svg: WorkflowCheck,
          },
        ],
      },
      reportTemplates:{
        id: "Report Templates",
        link: "/report-templates",
        svg: report,
      },
      delviumLogo,
      // defaultLogo,
      isPoweredByVisible: false,
    };
  },
  async mounted(){
    await this.navigationList();
    const themeObj = localStorage.getItem('themeObj');
    this.isPoweredByVisible = themeObj && JSON.parse(themeObj)?.powered_by;
  },
  watch: {
    isPermissionUpdate() {
      this.navigationList();
    },
    lang() {
      this.navigationList();
    },
  },
  computed: {
    ...mapGetters(['getThemeLogo', 'getIsPermission']),
    lang() {
      return this.$i18n.locale;
    },
    isNavCollapsed() {
      return this.$store.getters.getNavCollapsed || false;
    },
    isEmailNavCollapsed() {
      return this.$store.getters.getEmailNavCollapsed || false;
    },
    isPermissionUpdate() {
      return this.$store.getters.getIsPermission
    },
    navigationLabels() {
      return [
        {
          id: "Dashboard",
          name: this.$t("components.appsidebar.dashboard"),
        },
        //  Admin menu
        {
          id: "Admin",
          name: this.$t("components.appsidebar.admin"),
        },
        {
          id: "Fields",
          name: this.$t("components.appsidebar.fields"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.field_list"),
          ],
        },
        {
          id: "Checks",
          name: this.$t("components.appsidebar.checks"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.check_list"),
          ],
        },
        {
          id: "Packages",
          name: this.$t("components.appsidebar.packages"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.package_list"),
          ],
        },
        {
          id: "Risk Assessment",
          name: this.$t("components.appsidebar.risk_assessment"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.risk_assessment"),
          ],
        },
        {
          id: "Clients",
          name: this.$t("components.appsidebar.clients"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.client_list"),
          ],
        },
        {
          id: "Users",
          name: this.$t("components.appsidebar.users"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.users"),
          ],
        },
        {
          id: "Registered Domain",
          name: this.$t("components.appsidebar.registered_domain"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.registered_domain"),
          ],
        },
        {
          id: "Groups",
          name: this.$t("components.appsidebar.groups"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.groups"),
          ],
        },
        {
          id: "emails",
          name: this.$t("components.appsidebar.emails"),
          children: [
            {
              id: "Templates & Sequences",
              name: this.$t("components.appsidebar.templates_sequences"),
            },
            {
              id: "Default Template & Sequences",
              name: this.$t("components.appsidebar.default_template_sequences"),
            },
          ],
        },
        {
          id: "Forms",
          name: this.$t("components.appsidebar.forms"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.forms"),
          ],
        },
        {
          id: "Roles & Permissions",
          name: this.$t("components.appsidebar.roles_permissions"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.roles_permissions"),
          ],
        },
        {
          id: "Credit Packs",
          name: this.$t("components.appsidebar.credit_packs"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.credit_packs"),
          ],
        },
        {
          id: "License",
          name: this.$t("components.appsidebar.license"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.license"),
          ],
        },
        {
          id: "Manage Tax",
          name: this.$t("components.appsidebar.manage_tax"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.manage_tax"),
          ],
        },
        // Purcahse 
        {
          id: "Purchase",
          name: this.$t("components.appsidebar.purchase"),
        },
        {
          id: "Orders",
          name: this.$t("components.appsidebar.orders"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.purchase"),
            this.$t("components.appsidebar.orders"),
          ],
        },
        {
          id: "Invoices",
          name: this.$t("components.appsidebar.invoices"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.purchase"),
            this.$t("components.appsidebar.invoices"),
          ],
        },
        {
          id: "Sales",
          name: this.$t("components.appsidebar.sales")
        },
        {
          id: "Orders",
          link: "/sales-orders",
          name: this.$t("components.appsidebar.orders"),
          svg: salesOrders
        },
        {
          id: "Invoices",
          name: this.$t("components.appsidebar.invoices"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.sales"),
            this.$t("components.appsidebar.invoices"),
          ],
        },
        {
          id: "Settings",
          name: this.$t("components.appsidebar.settings"),
        },
        {
          id: "Logo & Theme",
          name: this.$t("components.appsidebar.logo_theme"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.settings"),
            this.$t("components.appsidebar.logo_theme"),
          ],
        },
        {
          id: "Custom Domain",
          name: this.$t("components.appsidebar.custom_domain"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.settings"),
            this.$t("components.appsidebar.custom_domain"),
          ],
        },
        {
          id: "Workflow Engine",
          name: this.$t("components.appsidebar.workflow_engine"),
          children: [
            {
              id: "Person",
              name: this.$t("components.appsidebar.person"),
              breadcrumbBranch: [
                this.$t("components.appsidebar.admin"),
                this.$t("components.appsidebar.workflow_engine"),
                this.$t("components.appsidebar.person"),
              ],
            },
            {
              id: "Company",
              name: this.$t("components.appsidebar.company"),
              breadcrumbBranch: [
                this.$t("components.appsidebar.admin"),
                this.$t("components.appsidebar.workflow_engine"),
                this.$t("components.appsidebar.company"),
              ],
            },
            {
              id: "Case",
              name: this.$t("components.appsidebar.case"),
              breadcrumbBranch: [
                this.$t("components.appsidebar.admin"),
                this.$t("components.appsidebar.workflow_engine"),
                this.$t("components.appsidebar.case"),
              ],
            },
            {
              id: "Checks",
              name: this.$t("components.appsidebar.checks"),
              breadcrumbBranch: [
                this.$t("components.appsidebar.admin"),
                this.$t("components.appsidebar.workflow_engine"),
                this.$t("components.appsidebar.checks"),
              ],
            },
          ],
        },
        {
          id: "Report Templates",
          name: this.$t("components.appsidebar.report_templates"),
          breadcrumbBranch: [
            this.$t("components.appsidebar.admin"),
            this.$t("components.appsidebar.report_templates"),
          ],
        },
      ]
    },
  },
  methods: {
    checkPermission,
    getNavLabels(nav, labelList = this.navigationLabels) {
      const navLabels = labelList.find((el) => el.id === nav.id) || null;
      const result = {
        ...navLabels,
        ...nav,
      }

      if (result.children) {
        result.children = result.children.map((childNav) => {
          const childLabels = navLabels.children;
          const childNavLabel = childLabels.find((navLabel) => childNav.id === navLabel.id) || null;
          return {
            ...childNavLabel,
            ...childNav,
          }
        });
      }

      return result;
    },
    // TODO this implementation must be reworked! Optimize all "checkPermissions", get rid of all array iterations after particular item has been pushed into it! Use computed instead of "navigation" in data + its mutations in callbacks and watchers
    navigationList() {
      this.navigation = [];

      if(checkPermission('dashboard.read') == true){
        this.navigation.push(this.getNavLabels(this.dashboard))
      }
      if (checkPermission('field.read') == true || checkPermission('block.read') == true || 
          checkPermission('check.read') == true || checkPermission('package.read') == true ||  
          checkPermission('form.read') == true || checkPermission('client.read') == true || 
          checkPermission('groups.read') == true || checkPermission('users.read') == true ||
          checkPermission('creditPack.read') == true || checkPermission('tax.read') == true ||
          checkPermission('license.read') == true ||  checkPermission('email.read') == true ||
          checkPermission('role.read') == true || checkPermission('workflow.read') == true ||
          checkPermission('riskAssessment.read') == true || checkPermission('reportTemplates.read') == true
        ) {
        this.navigation.push(this.getNavLabels(this.admin))
        this.navigation.forEach(elv => {
          if(elv.id == "Admin"){
            if(checkPermission('field.read') == true || checkPermission('block.read')){
              elv.children.push(this.getNavLabels(this.field))
            }
            if(checkPermission('check.read') == true){
              elv.children.push(this.getNavLabels(this.check))
            }
            if(checkPermission('package.read') == true){
              elv.children.push(this.getNavLabels(this.package))
            }
            if(checkPermission('form.read') == true){
              elv.children.push(this.getNavLabels(this.form))
            }
            if(checkPermission('client.read') == true){
              elv.children.push(this.getNavLabels(this.clientList))
            }
            if(checkPermission('groups.read') == true){
              elv.children.push(this.getNavLabels(this.group))
            }
            if(checkPermission('users.read') == true){
              elv.children.push(this.getNavLabels(this.user))
              elv.children.push(this.getNavLabels(this.registeredDomain));
            }
            if(checkPermission('creditPack.read') == true){
              elv.children.push(this.getNavLabels(this.creditPack))
            }
            if(checkPermission('tax.read') == true){
              elv.children.push(this.getNavLabels(this.tax))
            }
            if(checkPermission('license.read') == true){
              elv.children.push(this.getNavLabels(this.license))
            }
            if(checkPermission('email.read') == true){
              elv.children.push(this.getNavLabels(this.email))
            }
            if(checkPermission('role.read') == true){
              elv.children.push(this.getNavLabels(this.rolePermission))
            }
            if(checkPermission('workflow.read') == true){
              elv.children.push(this.getNavLabels(this.workflowEngine));
            }
            if(checkPermission('riskAssessment.read') == true){
              elv.children.push(this.getNavLabels(this.riskAssessment))
            }
            if(checkPermission('reportTemplates.read') == true){
              elv.children.push(this.getNavLabels(this.reportTemplates));
            }
           
            // if(checkPermission('check.read') == true){
            //   elv.children.push(this.check)
            // }
          }
        })
      }
      if(checkPermission('purchaseOrder.read') == true || checkPermission('invoice.read') == true )
        {
          this.navigation.push(this.getNavLabels(this.purchase))
          this.navigation.forEach(elv => {
            if(elv.id == "Purchase"){
              if(checkPermission('purchaseOrder.read') == true){
                elv.children.push(this.getNavLabels(this.purchaseOrder))
              }
              // if(checkPermission('invoice.read') == true){
              //   elv.children.push(this.purchaseInvoice)
              // }
            }
          })
        }
        if(checkPermission('salesOrder.read') == true || checkPermission('salesInvoice.read') == true )
        {
          this.navigation.push(this.getNavLabels(this.sales))
          this.navigation.forEach(elv => {
            if(elv.id == "Sales"){
              if(checkPermission('salesOrder.read') == true){
                elv.children.push(this.getNavLabels(this.salesOrders))
              }
              // if(checkPermission('salesInvoice.read') == true){
              //   elv.children.push(this.salesInvoice)
              // }
            }
          })
        }
        if(checkPermission('theme.edit') == true || checkPermission('domain.read') == true )
        {
          this.navigation.push(this.getNavLabels(this.setting))
          this.navigation.forEach(elv => {
            if(elv.id == "Settings"){
              if(checkPermission('theme.edit') == true){
                elv.children.push(this.getNavLabels(this.logo))
              }
              if(checkPermission('domain.read') == true){
                elv.children.push(this.getNavLabels(this.domain))
              }
            }
          })
        }
        // if (this.navigation?.length > 0 && !this.navigation.some(item => item.name == this.tool.name)) {
        //   this.navigation.push(this.tool)
        // }
    },
    isActive(item) {
      const breadcrumb = this.$route?.meta?.breadcrumb;
      return this.$route.path === item.link
        || this.$route.matched.some(el => el.path == item.link || el.path === item.pathAliase)
        // check breadcrumbs to determine child routes (in our case, routes of the same branch):
        || (!!breadcrumb && breadcrumb[0].name === item.breadcrumbBranch?.[0] && breadcrumb[1].name === item.breadcrumbBranch?.[1] && breadcrumb[2].name === item.breadcrumbBranch?.[2])
    },
    navCollapseToggle() {
      const navCollapsed = this.$store.getters.getNavCollapsed || false
      this.$store.dispatch('setNavCollapsed', !navCollapsed)
    },
    async handleNavClick(nav) {
      let dropdownElement = document.getElementById('dashboard_container_embeddedbi_ConfigMenu');
      if (dropdownElement) dropdownElement.style.display = 'none'
      if (!this.isActive(nav.link)) {
        if (nav.children && nav.children.length  > 0 && nav.id.toLowerCase() !== 'emails' &&  nav.id.toLowerCase() !== 'workflow engine') {
          let expanded = nav.expanded || false;
          this.navigation = this.navigation.map((nve) =>
            nve.link === nav.link ? { ...nav, expanded: !expanded, expandChildren: false } : nve
          );
          this.navigation.forEach(el => {
            if(el.children && el.id.toLowerCase() === 'admin') {
              el.children.forEach((el1) => {
                  el1.expandMenu = false;
              })
            }
          })
        }
        else if (nav.children && nav.children.length  > 0 && (nav.id.toLowerCase() === 'emails' || nav.id.toLowerCase() === 'workflow engine')) {
          console.log("2");
          let expanded = nav.expandMenu || false;
          // const expand = this.navigation;
          this.navigation.forEach(el => {
            if(el.children && el.id.toLowerCase() === 'admin') {
              el.children.forEach((el1) => {
                if(el1.link === nav.link) {
                  el1.expandMenu = !expanded
                }
              })
            }
          })
          // why do we use this cloneDeep??
          cloneDeep(this.navigation)
          let isExpandChildren = false;
          this.navigation.forEach((el) => {
            if(el.link === '/admin' && el.children) {
                el.children.forEach((child) => {
                  if(child?.expandMenu) {
                    isExpandChildren = true;
                  }
              });
            }
          })
          this.navigation = this.navigation.map((nve) =>
          nve.link === '/admin' ? { ...nve, expandChildren: isExpandChildren } : nve);

        }
         else {
          console.log("3");
          if (nav.customLink) {
            const payload = {
              company_id: this.$store.getters.getUser.id,
              user_id: this.$store.getters.getTenantId
            }
            const {data} = await getSaasUrl(payload)
            window.open(data.research_url, "_blank")
          } else  {
            this.$router
              .push({ path: nav.link })
              .catch((e) => console.log("e >> ", e));
          }
        }
      }
    },
    getNavElement(nav) {
      let dropdownElement = document.getElementById('dashboard_container_embeddedbi_ConfigMenu');
      if (dropdownElement) dropdownElement.style.display = 'none'
      const link = {
        bind: {
          is: 'router-link',
          tag: 'a',
          to: nav.link,
        },
      };
      const submenu = {
        bind: {
          is: 'div',
        },
        on: {
          'click': () => this.handleNavClick(nav),
        }
      };
      
      if (nav.customLink) {
        return submenu
      } else {
        return nav.children && nav.children.length > 0 ? submenu : link;
      }
    },
  },
};
